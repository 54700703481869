
.backgroundDiv
{
  position:fixed;
  bottom: 0;
  top:  0;
  left: 0;
  right:0;
  background-image: url("Images/Background.png");
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.MainComponent
{
    height: 100%;
    position: fixed;
    top: 5%;
    left: 5%;
    right: 5%;
}
.fullHeight
{
    height: 100%;
}
.InformationComponent
{
    background-color: rgb(62, 67, 82);
}

.hrdesign
{ 
    height: 90%;
    width:  1px;
    opacity: 10%;
}
body::-webkit-scrollbar 
{
    display: none;
}
.custom-scrollbar 
{
    direction: rtl;
    text-align: left;
}
::-webkit-scrollbar
{
    width: 20px;
}
::-webkit-scrollbar-thumb 
{
    background-color: #d6dee1;
    border-radius: 80px;
    border: 8px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover 
{
    background-color: #a8bbbf;
}
.buttonFullHeight
{
    height: 600px;
}
.checkboxdiv
{
    font-size: 0.75rem;
    height: 2em;
    margin-top: 1.75px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tabs a:hover {background-color: hsl(0, 0%, 30%) !important;}
.tabs li.is-active a {background-color: hsl(0, 0%, 30%) !important;}
.threeDots
{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.loaderCss {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 17vw;
    height:17vw;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.spantext
{
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.background-dark select {
    background-color: hsl(0, 0%, 21%)	 !important; /* Dark background color */
    color: #ffffff  !important;           /* White text color */
    border: none;             /* Optional: remove border */

}
.table td
{
    border: none !important; 
}